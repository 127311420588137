/* Version History
 * ISRDI Common CSS Version 1.6
 *  getting rid of mono mixed fonts and fonts crit, support for multi-weight on cabrito sans
 *  added false-th class for tds.
 * ISRDI Common CSS Version 1.5
 *  external link icons fixed for multi-line links (only appear on first line)
 * ISRDI Common CSS Version 1.4
 *  supports more multi-project approach, split off base layout
 *  added accordions
 *  added button styling
 *  removed deprecated classes .list-spaced, .list-plain, 
 *    .list-floated, and .list-dd-floated.  we can no use more
 *    generic .spaced, .floated, and .no-embellishment instead
 * IPUMS css version 1.3
 *  updated left nav for multi-level menus
 * IPUMS css version 1.2
 *  support for user bar
 *  changes for multi-project menu
 *  added table row no-hover class
 *  tweaked header hights and spacing so blue background bar was in line with header background
 * IPUMS css version 1.4
 *  started using common project elements and common isrdi elements
 *  added align-center for figures and images
 */
@import 'common-isrdi-variables.1.0';
@import 'common-isrdi-extends.1.1';
@import 'common-isrdi-fonts.1.1';
// @import 'functions';


// ***
// * BEGIN Basic Layout
// ***
html,
body {
  box-sizing: border-box;
  height: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  margin: 0;
  padding: 0;
}
// ***
// * END Basic Layout
// ***

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***
html, body {
  color: $color_primary;
  background-color:$color_bkg_dark;
}
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  line-height:1.2em;
}
h1 {
  margin-bottom: 2.3rem;
}
h2 {
  margin-top:3.2rem;
}
h3 {
  margin-top:2.5rem;
}
h4,h5,h6 { margin-top:2rem; }
h4 {
  & + p, 
  & + ul,
  & + ol {
    margin-top:0.5rem;
  }
}
h5, h6 {
  & + p, 
  & + ul,
  & + ol {
    margin-top:0.3rem;
  }
}
p {
  margin: 1em 0;
  &:first-child {
    margin-top:0;
  }
  &:last-child {
    margin-bottom:0;
  }
}

// ***
// * END Visual Styles for Page Structural Elements
// ***

// ***
// * BEGIN General Tag Styling
// ***
a {
  &:link {
    color: $color_link;
  }
  &:visited {
    color: $color_link_visited;
  }
  &:hover, &:active {
    color: $color_link_ha;
  }
  &.button {
    @include button($color_near_white, $color_link, $color_link, $color_secondary_medium, $color_near_white, $color_secondary_dark );
  }

  /* add external link icon */
  &[href^="http"], &[href^="HTTP"] {
    background-repeat: no-repeat;
    background-position: right 1px top 0.2em;
    background-size: auto 0.8em;
    padding-right: 1.1em;
    &.no-link-icon,
    .no-link-icon & {
      @extend %no-link-icon;
      &.button {
        padding-right: 2.5rem;
      }
    }
    /* buttons need icon moved up a bit */
    &.button {
      background-position: right 0.4em top 0.4em;
      background-size: auto 50%;
      padding-right: 2.5rem;
    }
    background-image: url("//assets.ipums.org/_img/ico-external-link-secondary-dark-40px.png");
  }
  /* add file type icons */
  &[href$=".pdf"], &[href$=".PDF"], &[href$=".Pdf"],
  &[href$=".zip"], &[href$=".ZIP"], &[href$=".Zip"],
  &[href$=".xls"], &[href$=".xlsx"],
  &[href$=".doc"], &[href$=".docx"]
  {    
    background-repeat: no-repeat;
    background-position: right 0 top 0;
    background-size: auto 90%;
    padding-right: 1.2em;

    &[data-size]:after { content: " (" attr(data-size) ")"; }

    &.no-file-icon,
    .no-file-icon & {
    /* note that this will also kill external link icons */
      @extend %no-link-icon;
    }
    /* buttons need icon moved up a bit */
    &.button {
      background-position: right 0.4em top 0.2em;
      background-size: auto 70%;
      vertical-align: baseline;
      padding-right: 2.5rem;
    }
  }
  &[href$=".pdf"], &[href$=".PDF"], &[href$=".Pdf"] {
    background-image: url("//assets.ipums.org/_img/ico-pdf-link-color-40px.png");
  }
  &[href$=".zip"], &[href$=".ZIP"], &[href$=".Zip"] {
    background-image: url("//assets.ipums.org/_img/ico-zip-link-color-40px.png");
  }
  &[href$=".xls"], &[href$=".xlsx"] {
    background-image: url("//assets.ipums.org/_img/ico-xls-link-color-40px.png");
  }
  &[href$=".doc"], &[href$=".docx"] {
    background-image: url("//assets.ipums.org/_img/ico-doc-link-color-40px.png");
  }

  /* add arrow in front of back to top links */
  &[href="#page-title"]:before {
    content: '\25B4\00a0';
    text-decoration:none;
  }

}
nav a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  } 
}
h1,h2,h3,h4 {
  color: $color_primary;
}
h1 {
  font-size:($base_font_size*1.4);
  text-transform: uppercase;
}
h2 {
  font-size:($base_font_size*1.2);
  text-transform: uppercase;
}
h3 {
  font-size:($base_font_size*1.15);
  margin:2.5rem 0 0 0;
  text-transform: capitalize;

  .subsection-links & {
    text-transform: uppercase; 
  }
}
h4 {
  font-size:($base_font_size*1.1);
  border-bottom:1px solid $color_secondary_medium;
}
h5 {
  font-size:($base_font_size*1.05);
}
h6 {
  font-size:$base_font_size;
}
img {
  border: 0;

  &.border {
    border: 1px solid $color_primary;
  }

  &.align-center {
    display:block;
    margin-left:auto;
    margin-right:auto;
  }
}
table {
  width:100%;
  margin:2em auto 1em auto;
  caption-side:top;
  empty-cells:show;
  border-collapse: collapse;
  border-spacing: 0;
  border:none;
  @extend %tnum;

  &.width-auto {
    width:auto;
    margin-left:0;
    margin-right:0;
  }
}
caption {
  font-size:($base_font_size*1.1);
  margin:0.2em 0;
  text-align:left;
}
td, th {
  color:$color_primary;
  padding:0.5rem 1rem;
  border:1px solid $color_secondary_medium;
  text-align:left;
  background-size:100% 100%;
  background-repeat:no-repeat;
  vertical-align: top;
}
th, td.false-th {
  font-weight: normal;
  background-color:darken($color_secondary_light,5%);
  a {
    &:link {
      color: darken($color_primary,5%);
    }
    & {
      &:visited {
        color: $color_link;
      }
      &:hover {
        color: $color_link_ha;
      }
      &:active {
        color: $color-near-white;
      }
    }
  }
}
thead tr:not(.subhead) th,
thead tr:not(.subhead) td.false-th  {
  color:$color_near_white;
  border-color:$color_secondary_dark;
  background-color:$color_bkg_dark;
  padding:1rem 1rem;
  text-align:center;
  a:link {
    color: $color_primary_light;
  }
}
tfoot td {
  font-size: $base_font_size*0.85;
  margin:0.5em 0;
  border:none;
}
table:not(.no-hover) tr:hover td:not(.false-th) {
  background-color:transparentize($color_bkg_dark,.85);  // background-image:linear-gradient(to bottom,transparentize($color_primary_dark,.85),transparentize($color_primary_dark,.85));
}
ul, ol, dl {
  @extend %tnum;
}
ol {
  list-style-type:decimal;
}
ol ol {
  list-style-type:lower-alpha;
}
ol ol ol {
  list-style-type:upper-roman;
}
ol ol ol ol {
  list-style-type:lower-roman;
}
blockquote {
// put a pale bar to the left of blockquotes
  padding:0 $width_gutter;
  margin:$width_gutter 0;

  p {
    font-style: italic;
    border-left:($width_gutter/8) solid fade-out($color_secondary_medium,0.5);
    margin:0;
    padding:0.7rem 0 0.7rem ($width_gutter/3);
  }
}
figure {
  &:not(.align-center) {
    margin-left:0;
    margin-right:0;
  }
}
figcaption {
  font-size:($base_font_size*1.1);
  margin:0.2em 0;
  text-transform: capitalize;
  text-align:left;
}
cite {
  display:block;
  margin:$width_gutter $width_gutter;
}
hr {
  border: 0;
  color: $color_primary_dark;
  background-color: $color_primary_dark;
  height:1px;
  width:100%;
}
// prevent these from impacting line height. 
sup,sub {
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
  font-size:0.9em;

  & a {
    font-size:0.9em;
  }
}
// give sup some space so it can be next to it's content
sup {
  padding-left:0.25em;
}
sub {
  top: 0.4em;
}
// ***
// * END General Tag Styling
// ***

// ***
// * BEGIN Specialized Classes
// ***
.clearfix {
  @extend %clearfix;
}
.visually-hidden { 
  @extend %sr-only;
}
.alert {
  background-repeat: no-repeat;
  background-position: 1rem 1.1rem;
  padding: 1.2rem 1rem 1rem 4rem;
  border: 1px solid $color_info;
  color: $color_info;
  background-color:mix($color_info,$color_near_white, 14%);
  background-image:url("//assets.ipums.org/_img/ico-alert-info.png");
  background-size:2.0rem 2.0rem;
  margin-bottom:1.5rem;
}
.alert-info {
  border-color: $color_info;
  color: $color_info;
  background-color:mix($color_info,$color_near_white, 14%);
  background-image:url("//assets.ipums.org/_img/ico-alert-info.png");
}
.alert-success {
  border-color: $color-success;
  color: $color-success;
  background-color:mix($color-success,$color_near_white, 15%);
  background-image:url("//assets.ipums.org/_img/ico-alert-success.png");
}
.alert-warning {
  border-color: $color_warning;
  color: $color_warning;
  background-color: $color_bkg_warning;
  background-image:url("//assets.ipums.org/_img/ico-alert-warning.png");
}
.alert-danger {
  border-color: $color_error;
  color: $color_error;
  background-color:mix($color_error,$color_near_white, 15%);
  background-image:url("//assets.ipums.org/_img/ico-alert-error.png");
}
.tnum {
  @extend %tnum;
}
.no-tnum {
  font-variant-numeric: proportional-nums;
  -moz-font-feature-settings: "tnum" off;
  -webkit-font-feature-settings: "tnum" off;
  font-feature-settings: "tnum" off;
}
.no-embellishment {
  @at-root ul#{&} { // ul.plain
    list-style-type:none;
  }
  @at-root ol#{&} { // ol.plain
    list-style-type:none;
  }
}
.no-indent {
  @at-root dl#{&} { // dl.no-indent
    & dt {
      margin-top:0.4em;
      &:first-child {
        margin-top:0;
      }
    }
    & dd {
      margin-left:0;
      & > *:first-child {
        margin-top:0.2em;
      }
    }
    &.floated dd {
      margin-left:0;
    }
  }
}
.bulleted {
  @at-root dl#{&} { // dl.bulleted
    dd {
      display: list-item;
      list-style-type: disc;
      list-style-position: inside;
    }
  }
}
.floated {
  @at-root dl#{&} { // dl.floated 
    dt {
      display:inline;
    }
    dd {
      display:inline-block;
      margin-left:1rem;
    }
    dd + dt:before {
      content:" ";
      display:block;
    }
  }
  @at-root img#{&} { // img.floated
    float:left;

    &.align-right {
      float:right;
      margin-left:1rem;
      margin-bottom:1rem;
    }

    &.align-left {
      float:left;
      margin-right:1rem;
      margin-bottom:1rem;
    }
  }
  // #TODO can this be combined with above?
  @at-root figure#{&} { // figure.floated
    float:left;

    &.align-right {
      float:right;
      margin-left:1rem;
      margin-bottom:1rem;
    }

    &.align-left {
      float:left;
      margin-right:1rem;
      margin-bottom:1rem;
    }
  }
}
.floated-child {
  @at-root dl#{&} { // dl.floated-child
    dd {
      display:inline-block;
      margin-left:1rem;
    }
  }
}
.spaced {
  > li,
  > dd,
  > dt {
    margin-bottom:$width_gutter / 2;
  }
}
dl.list-fixed-dt {
  dt {
    float: left;
    clear: left;
    min-width: 4rem;
    margin-right:0.5rem;
  }
  dd {
    margin-left:4.5rem;
  }
  &::after {
    content: " ";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; 
   }
}
.list-tabular {
  @at-root ul#{&} {
    position:relative;
    width:100%;
    padding-left:0;

    &:after {
      content: '\00a0';
      clear: both;
    }
  }
  -webkit-column-count: 5;
    -moz-column-count: 5;
         column-count: 5;
  & li {
    list-style-type: none;
  }
  &.col-2 {
    -webkit-column-count: 2;
      -moz-column-count: 2;
           column-count: 2;
  }
  &.col-3 {
    -webkit-column-count: 3;
      -moz-column-count: 3;
           column-count: 3;
  }
  &.col-4 {
    -webkit-column-count: 4;
      -moz-column-count: 4;
           column-count: 4;
  }
  &.col-5 {
    -webkit-column-count: 5;
      -moz-column-count: 5;
           column-count: 5;
  }
  &.col-6 {
    -webkit-column-count: 6;
      -moz-column-count: 6;
           column-count: 6;
  }
  &.col-7 {
    -webkit-column-count: 7;
      -moz-column-count: 7;
           column-count: 7;
  }
  &.col-8 {
    -webkit-column-count: 8;
      -moz-column-count: 8;
           column-count: 8;
  }
  &.col-9 {
    -webkit-column-count: 9;
      -moz-column-count: 9;
           column-count: 9;
  }
  &.col-10 {
    -webkit-column-count: 10;
      -moz-column-count: 10;
           column-count: 10;
  }
  &.left-to-right {
    -webkit-column-count: auto;
      -moz-column-count: auto;
           column-count: auto;
    li {
      float:left;
      width:20%;
    }
    &.col-2 li { width:(100% / 2); }
    &.col-3 li { width:(100% / 3); }
    &.col-4 li { width:(100% / 4); }
    &.col-5 li { width:(100% / 5); }
    &.col-6 li { width:(100% / 6); }
    &.col-7 li { width:(100% / 7); }
    &.col-8 li { width:(100% / 8); }
    &.col-9 li { width:(100% / 9); }
    &.col-10 li { width:(100% / 10); }
  }
  & table {
    margin:0 auto;
    break-inside: avoid-column;
    break-after: auto;
  }
  &.align-center li {
    text-align: center;
  }
}
.list-menu {
  @extend %menu-list;
}
table.no-border {
  td, th {
    border:none;
    padding-top:0.2em;
    padding-bottom:0.2em;
  }
}
table.condensed {
  font-size:0.8em;
  td, th {
    padding:0.4rem 0.4rem;
    line-height: 1.7rem;
  }
}
table.no-full-width {
  width:auto;
  margin-left:0;
  margin-right:0;
}
table.striping:not(.no-hover)  tr:nth-child(2n) td {
  background-image:linear-gradient(to bottom,transparentize($color_primary,.98),transparentize($color_primary, .98));
}
table.striping:not(.no-hover)  tr:nth-child(2n) th {
  background-image:linear-gradient(to bottom,transparentize($color_primary,.97),transparentize($color_primary, .97)),
        linear-gradient(to bottom,darken($color_secondary_light,5%),darken($color_secondary_light,5%));
}
table.striping:not(.no-hover) tr:nth-child(2n):hover td {
  background-image:linear-gradient(to bottom,transparentize($color_primary,.85),transparentize($color_primary,.85)),
        linear-gradient(to bottom,transparentize($color_primary,.98),transparentize($color_primary, .98));
}
//utility to left align columns using table classes (default)
//these are duplicated with additional specificity in base-ipums-drupal-shim
.align-left td, 
.align-left th,
.align-left-col-1 td:nth-child(1),
.align-left-col-2 td:nth-child(2),
.align-left-col-3 td:nth-child(3),
.align-left-col-4 td:nth-child(4),
.align-left-col-5 td:nth-child(5),
.align-left-col-6 td:nth-child(6),
.align-left-col-7 td:nth-child(7),
.align-left-col-8 td:nth-child(8),
.align-left-col-9 td:nth-child(9),
.align-left-col-10 td:nth-child(10),
.align-left-col-11 td:nth-child(11),
.align-left-col-12 td:nth-child(12),
.align-left-col-13 td:nth-child(13),
.align-left-col-14 td:nth-child(14),
.align-left-col-15 td:nth-child(15),
.align-left-col-1 th:nth-child(1),
.align-left-col-2 th:nth-child(2),
.align-left-col-3 th:nth-child(3),
.align-left-col-4 th:nth-child(4),
.align-left-col-5 th:nth-child(5),
.align-left-col-6 th:nth-child(6),
.align-left-col-7 th:nth-child(7),
.align-left-col-8 th:nth-child(8),
.align-left-col-9 th:nth-child(9),
.align-left-col-10 th:nth-child(10),
.align-left-col-11 th:nth-child(11),
.align-left-col-12 th:nth-child(12),
.align-left-col-13 th:nth-child(13),
.align-left-col-14 th:nth-child(14),
.align-left-col-15 th:nth-child(15) {
  text-align: left;
}
//utility to center columns using table classes
//these are duplicated with additional specificity in base-ipums-drupal-shim
.align-center td, 
.align-center th,
.align-center-col-1 td:nth-child(1),
.align-center-col-2 td:nth-child(2),
.align-center-col-3 td:nth-child(3),
.align-center-col-4 td:nth-child(4),
.align-center-col-5 td:nth-child(5),
.align-center-col-6 td:nth-child(6),
.align-center-col-7 td:nth-child(7),
.align-center-col-8 td:nth-child(8),
.align-center-col-9 td:nth-child(9),
.align-center-col-10 td:nth-child(10),
.align-center-col-11 td:nth-child(11),
.align-center-col-12 td:nth-child(12),
.align-center-col-13 td:nth-child(13),
.align-center-col-14 td:nth-child(14),
.align-center-col-15 td:nth-child(15),
.align-center-col-1 th:nth-child(1),
.align-center-col-2 th:nth-child(2),
.align-center-col-3 th:nth-child(3),
.align-center-col-4 th:nth-child(4),
.align-center-col-5 th:nth-child(5),
.align-center-col-6 th:nth-child(6),
.align-center-col-7 th:nth-child(7),
.align-center-col-8 th:nth-child(8),
.align-center-col-9 th:nth-child(9),
.align-center-col-10 th:nth-child(10),
.align-center-col-11 th:nth-child(11),
.align-center-col-12 th:nth-child(12),
.align-center-col-13 th:nth-child(13),
.align-center-col-14 th:nth-child(14),
.align-center-col-15 th:nth-child(15) {
  text-align: center;
}
//utility to right align columns using table classes
//these are duplicated with additional specificity in base-ipums-drupal-shim
.align-right td, 
.align-right th,
.align-right-col-1 td:nth-child(1),
.align-right-col-2 td:nth-child(2),
.align-right-col-3 td:nth-child(3),
.align-right-col-4 td:nth-child(4),
.align-right-col-5 td:nth-child(5),
.align-right-col-6 td:nth-child(6),
.align-right-col-7 td:nth-child(7),
.align-right-col-8 td:nth-child(8),
.align-right-col-9 td:nth-child(9),
.align-right-col-10 td:nth-child(10),
.align-right-col-11 td:nth-child(11),
.align-right-col-12 td:nth-child(12),
.align-right-col-13 td:nth-child(13),
.align-right-col-14 td:nth-child(14),
.align-right-col-15 td:nth-child(15),
.align-right-col-1 th:nth-child(1),
.align-right-col-2 th:nth-child(2),
.align-right-col-3 th:nth-child(3),
.align-right-col-4 th:nth-child(4),
.align-right-col-5 th:nth-child(5),
.align-right-col-6 th:nth-child(6),
.align-right-col-7 th:nth-child(7),
.align-right-col-8 th:nth-child(8),
.align-right-col-9 th:nth-child(9),
.align-right-col-10 th:nth-child(10),
.align-right-col-11 th:nth-child(11),
.align-right-col-12 th:nth-child(12),
.align-right-col-13 th:nth-child(13),
.align-right-col-14 th:nth-child(14),
.align-right-col-15 th:nth-child(15) {
  text-align: right;
}
//utility to vertically middle align columns using table or row classes
//these are duplicated with additional specificity in base-ipums-drupal-shim
.align-middle td, 
.align-middle th {
  vertical-align:middle;
}
//utility to make columns have no-wrap
.no-wrap-col-1 td:nth-child(1),
.no-wrap-col-2 td:nth-child(2),
.no-wrap-col-3 td:nth-child(3),
.no-wrap-col-4 td:nth-child(4),
.no-wrap-col-5 td:nth-child(5),
.no-wrap-col-6 td:nth-child(6),
.no-wrap-col-7 td:nth-child(7),
.no-wrap-col-8 td:nth-child(8),
.no-wrap-col-9 td:nth-child(9),
.no-wrap-col-10 td:nth-child(10),
.no-wrap-col-11 td:nth-child(11),
.no-wrap-col-12 td:nth-child(12),
.no-wrap-col-13 td:nth-child(13),
.no-wrap-col-14 td:nth-child(14),
.no-wrap-col-15 td:nth-child(15),
.no-wrap-col-1 th:nth-child(1),
.no-wrap-col-2 th:nth-child(2),
.no-wrap-col-3 th:nth-child(3),
.no-wrap-col-4 th:nth-child(4),
.no-wrap-col-5 th:nth-child(5),
.no-wrap-col-6 th:nth-child(6),
.no-wrap-col-7 th:nth-child(7),
.no-wrap-col-8 th:nth-child(8),
.no-wrap-col-9 th:nth-child(9),
.no-wrap-col-10 th:nth-child(10),
.no-wrap-col-11 th:nth-child(11),
.no-wrap-col-12 th:nth-child(12),
.no-wrap-col-13 th:nth-child(13),
.no-wrap-col-14 th:nth-child(14),
.no-wrap-col-15 th:nth-child(15) {
  white-space: nowrap;
}
// use mono mixed fonts
// this has been changed to use the tnum override with the hope that will now work
.mono-mixed-col-1 td:nth-child(1),
.mono-mixed-col-2 td:nth-child(2),
.mono-mixed-col-3 td:nth-child(3),
.mono-mixed-col-4 td:nth-child(4),
.mono-mixed-col-5 td:nth-child(5),
.mono-mixed-col-6 td:nth-child(6),
.mono-mixed-col-7 td:nth-child(7),
.mono-mixed-col-8 td:nth-child(8),
.mono-mixed-col-9 td:nth-child(9),
.mono-mixed-col-10 td:nth-child(10),
.mono-mixed-col-11 td:nth-child(11),
.mono-mixed-col-12 td:nth-child(12),
.mono-mixed-col-13 td:nth-child(13),
.mono-mixed-col-14 td:nth-child(14),
.mono-mixed-col-15 td:nth-child(15) {
  @extend %tnum; 
}

// accordion support 
.accordion-header {
  font-weight: normal;
  font-size: 1.5rem;
  text-transform: capitalize;
  border:1px solid $color_secondary_medium;
  margin: 0px;
  cursor: pointer;
  background-color: $color_primary;
  color: $color_near_white;
  padding: 0.5rem 1rem;
  &:hover {
    opacity: 0.9;
  }
  &:after {
    font-size: 1rem;
    content: '\00a0\25b8';
    float: right;
    display: inline-block;
  }
  &.down:after {
    font-size: 1rem;
    content: '\00a0\25be';
    float: right;
    display: inline-block;
  }
}
.accordion-content > .accordion-header {
  background-color: $color_info;
  color: $color_near_white;
  padding: 0.5rem 1rem 0.5rem 1.4rem;
}
.accordion table {
  margin: 0px;
}
.accordion-expand-holder {
  width: 100%;
  text-align: right;
}
.accordion-selected {
  opacity: 0.9;
}

// ***
// * BEGIN responsive adaptations
// ***

/* fold tables to be narrower when necessary.  this approach 
  is NOT based on a mobile first design philosophy 
  based on Chris Coyer's approach describe at
  https://elvery.net/demo/responsive-tables/
*/
@media only screen and (max-width: $width_narrow_page) {
  table.folding-table {
    /* Force table to not be like tables anymore */
    &, thead, tbody, th, td, tr { 
        display: block; 
    }

    /* don't show cells with no data as rows */
    empty-cells: hide;
    td:empty {display:none; }

    /* Hide table headers (but not display: none;, for accessibility)
      duplicate what we use in @extend %hidden */
    thead tr { 
      position:absolute;
      left:-10000px;
      top:auto;
      width:1px;
      height:1px;
      overflow:hidden;
    }
    /* if the first cell is a header, center it */
    th:nth-child(1) {
      text-align:center;
    }
    td { 
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid $color_primary_light; 
      border-left: 1px solid $color_primary_light; 
      border-right: 1px solid $color_primary_light; 
      position: relative;
      padding-left: 50%; 
      white-space: normal;
      text-align:left;
    }
    td:before { 
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%; 
      padding-right: 10px; 
      white-space: nowrap;
      text-align:left;
      font-weight: bold;
    }
    /*
    Label the data
    */
    td:before { 
      content: attr(data-title); 
    }
  }
}

