/* common-isrdi-fonts.1.1
 *  switched to new fonts to flatten in tabular numbers and simplify main faces
 */

// base fonts for page
html {
  font-size: 62.5%; 
}
// this should only be needed for the body tag, but includes some additional
//   tags to override umn stylesheets
body,
header,
div[id="content"],
footer
{
  @extend %font-normal-base;
  font-size: $base-font-size; 
  line-height: 1.4em;

  .fonts-all & {
    @extend %font-normal;
  }
}

// tags with the normal font
h1,
h4,
h6,
th a,
footer p,
/* static sites */
div[id="content"],
nav[id="multi_project_menu"],
body > header blockquote,
.callout h2,
section[id="supported-by"],
/* drupal sites */
body.site-name-ipums header[id="site-header"] .block-superfish li.sf-depth-2 a, // top nav, submenu
body.site-name-ipums-d9 header[id="site-header"] .block-superfish li.sf-depth-2 a,
div[id="main-menu"] ul ul
{
  @extend %font-normal-base;

  .fonts-all & {
    @extend %font-normal;
  }
}

// tags with the demi font (stronger, multicase)
h2,
h3,
h5,
strong,
b,
caption,
th,
.page tr th,
figcaption,
dt, 
footer,
a.button,
/* static sites */
nav[id="user_bar"] a,     
nav[id="multi_project_menu"] .list-menu,
nav[id="sidebar-left"] li li,
/* drupal sites */
header[id="site-header"] .block-superfish a, // top nav
body.site-name-ipums header[id="site-header"] .block-superfish li.sf-depth-2 a.is-active, // top nav, submenu
body.site-name-ipums-d9 header[id="site-header"] .block-superfish li.sf-depth-2 a.is-active,
aside[id="sidebar-first"] .menu, // left nav
.grid-page .page-name // page grids
{
  @extend %font-demi-base;

  .fonts-all & {
    @extend %font-demi;
  }
}

// tags with the bold font (strongest, uppercase)
footer nav,
nav[id="multi_project_menu"] p,
nav[id="sidebar-left"] h3,
nav[id="sidebar-left"] li h4,
.buttonbox h3,
.tagline-home
{
  @extend %font-bold-base;

  .fonts-all & {
    @extend %font-bold;
  }
}

